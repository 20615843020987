/**
 * Import here any npm modules and your own js/scss
 * You can import npm modules as css, scss or js
 */

/**************
 * Javascript Libraries
 **************/

// Lazy-loading images
import "lazysizes";

/**************
 * App Initialization
 **************/
import App from "./js/app";
window.App = new App();

/**************
 * Styles
 **************/

// Icons (Lazy-load non-critical CSS)
import "../icons/icons.scss";

//Bootstrap components
import "./css/app/base/bootstrap.scss";

// Application Specific Styles
import "./css/resources/variables.scss";
import "./css/app/base/html.scss";
import "./css/app/base/typography.scss";
import "./css/app/components/header.scss";
import "./css/app/components/footer.scss";
import "./css/app/components/buttons.scss";

import "./css/app/custom.scss";
